import C = require("Everlaw/Constants");
import ConstrainedBox = require("Everlaw/UI/ConstrainedBox");
import Is = require("Everlaw/Core/Is");
import UI_Validated = require("Everlaw/UI/Validated");
import dojo_query = require("dojo/query");

class NumberBox extends ConstrainedBox<number> {
    static placeholder = "#";
    static textAlign = "inherit";
    private initialMin = C.INTMIN;
    private initialMax = C.INTMAX;
    boxType() {
        return UI_Validated.ValidatedNumber;
    }
    constructor(params: NumberBox.Params = {}) {
        super(
            Object.assign(
                {
                    placeholder: NumberBox.placeholder,
                    textAlign: NumberBox.textAlign,
                },
                params,
            ),
        );
        this.form.setTextBoxAriaLabel("Enter number");
        if (Is.number(params.min)) {
            this.initialMin = params.min;
            this.setMin(this.initialMin);
        }
        if (Is.number(params.max)) {
            this.initialMax = params.max;
            this.setMax(this.initialMax);
        }
        if (params.integerValued) {
            (this.form as UI_Validated.ValidatedNumber).setIntegerValued(params.integerValued);
        }
    }

    override getValue() {
        let val = this.form.getValue();
        // distinguish between empty string and actually not a value
        if (isNaN(val)) {
            if (this.isBlank()) {
                return null;
            }
            return val;
        } else {
            return val;
        }
    }
    isBlank() {
        return this.form.isEmpty();
    }
    isValid() {
        return this.form.isValid();
    }
    override resetMin() {
        this.setMin(this.initialMin);
    }
    override resetMax() {
        this.setMax(this.initialMax);
    }
    override blur() {
        super.blur();
        const inputNode = dojo_query("input.dijitInputInner", this.node)[0];
        inputNode && inputNode.blur();
    }
}

module NumberBox {
    export interface Params extends ConstrainedBox.Params {
        min?: number;
        max?: number;
        integerValued?: boolean;
    }
}

export = NumberBox;

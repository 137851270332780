import DateBox = require("Everlaw/UI/DateBox");

class LocalDateBox extends DateBox {
    protected override adjust(date: number): Date {
        return new Date(date);
    }
    protected override unadjust(date: number): number {
        return date;
    }
}

export = LocalDateBox;

import ConstrainedBox = require("Everlaw/UI/ConstrainedBox");

class RangeConstraintManager<Value, Widg extends ConstrainedBox<Value>> {
    constructor(
        private begin: Widg,
        private end: Widg,
    ) {}
    valueChange(
        beginChanged: boolean,
        value: Value,
        submitMethod?: (begin: Value, end: Value) => void,
        checkMethod?: (node: Widg, value: Value) => boolean,
    ) {
        if (checkMethod && !checkMethod(beginChanged ? this.begin : this.end, value)) {
            return;
        }

        if (value != null) {
            // We allow null values for open-ended searches, but don't want to constrain
            // the other box to an impossible value.
            // There is an issue where, despite the type decoration for min and max, string values
            // can sometimes get passed into setMin and setMax, which will cause issues with the invalid
            // message above, so we need to check and correct for that.
            if (beginChanged) {
                if (typeof value === "string" && value === "") {
                    this.end.setMin(undefined);
                } else if (typeof value === "string") {
                    this.end.setMin(parseInt(value));
                } else {
                    this.end.setMin(value);
                }
            } else {
                if (typeof value === "string" && value === "") {
                    this.begin.setMax(undefined);
                } else if (typeof value === "string") {
                    this.begin.setMax(parseInt(value));
                } else {
                    this.begin.setMax(value);
                }
            }
        } else {
            if (beginChanged) {
                this.end.resetMin();
            } else {
                this.begin.resetMax();
            }
        }

        if (submitMethod) {
            submitMethod(this.begin.getValue(), this.end.getValue());
        }
    }
}

module RangeConstraintManager {}

export = RangeConstraintManager;

import ConstrainedBox = require("Everlaw/UI/ConstrainedBox");
import Dom = require("Everlaw/Dom");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import RangeConstraintManager = require("Everlaw/UI/RangeConstraintManager");
import UI = require("Everlaw/UI");
import Widget = require("Everlaw/UI/Widget");
import dojo_keys = require("dojo/keys");
import { TimezoneN } from "Everlaw/DateUtil";

abstract class RangeWidget<V, C extends ConstrainedBox<V>, P extends ConstrainedBox.Params>
    extends FocusContainerWidget
    implements Widget.WithSettableValue
{
    begin: C = null;
    end: C = null;
    private rcm: RangeConstraintManager<V, C>;
    abstract buildWidget(params: P): C;
    type: import("Everlaw/Type").Type = null; // Optional, enables value checking.
    /** A value that can be used in ConstrainedBox#setValue. */
    defaultOnInvalidValue: any = "";
    // Called after the end value is submitted
    onSubmit(begin: V, end: V) {}
    // Called when either the beginning or end value is changed.
    onChange() {}
    private excludeLabel: HTMLElement;
    constructor(params: RangeWidget.Params<P>) {
        super(Dom.div());
        const boxParams: P = params.defaultBoxParams || <P>{};
        const inlineFormat = { inline: true };
        if (boxParams.boxFormat) {
            boxParams.boxFormat.inline = true;
        } else {
            boxParams.boxFormat = inlineFormat;
        }
        if (params.width) {
            boxParams.width = params.width;
        }

        const beginParams: P = <P>{};
        beginParams.onSubmit = (val) =>
            this.valueChange(
                true,
                this.begin.getValue(),
                params.doNotFocusEndAfterBeginSubmit ? null : () => this.end.focus(),
            );
        beginParams.onBlur = () => {
            this.valueChange(true, this.begin.getValue());
            this.end.getForm().validate();
        };
        beginParams.onChange = (val) => this.valueChange(true, this.begin.getValue());
        // We have to initialize begin and end here...
        if (params.beginPlaceholder) {
            beginParams.placeholder = params.beginPlaceholder;
        }
        this.begin = this.buildWidget(Object.assign(beginParams, boxParams));

        const endParams: P = <P>{};
        if (params.endPlaceholder) {
            endParams.placeholder = params.endPlaceholder;
        }
        endParams.onSubmit = (val) =>
            this.valueChange(false, this.end.getValue(), (begin, end) => this.onSubmit(begin, end));
        endParams.onBlur = () => {
            this.valueChange(false, this.end.getValue());
            this.end.getForm().validate();
        };
        endParams.onChange = (val) => this.valueChange(false, this.end.getValue());
        this.end = this.buildWidget(Object.assign(endParams, boxParams));

        this.begin.rwInfo = {
            other: this.end,
            isBegin: true,
        };
        this.end.rwInfo = {
            other: this.begin,
            isBegin: false,
        };
        this.connect(this.begin.getNode(), "keydown", this.onKeyDown.bind(this));
        this.connect(this.end.getNode(), "keydown", this.onKeyDown.bind(this));
        this.rcm = new RangeConstraintManager(this.begin, this.end);
        if (params.boxStyle) {
            Dom.style(this.begin, params.boxStyle);
            Dom.style(this.end, params.boxStyle);
        }
        if (params.addLabels) {
            const units = params.unitType ? " (" + params.unitType + ")" : "";
            this.excludeLabel = Dom.span({}, "Exclude ");
            const fromLabel = Dom.span({ class: "h7" }, this.excludeLabel, "From" + units);
            const toLabel = Dom.span({ class: "second-child h7" }, "To" + units);
            if (params.labelsClass) {
                Dom.addClass([this.excludeLabel, fromLabel, toLabel], params.labelsClass);
            }
            if (params.seperateBeginAndEndContainers) {
                Dom.addContent(this.node, [
                    Dom.div(
                        { class: "range-widget__begin-container" },
                        fromLabel,
                        this.begin.getNode(),
                    ),
                    Dom.div({ class: "range-widget__end-container" }, toLabel, this.end.getNode()),
                ]);
            } else {
                Dom.addContent(this.node, [
                    fromLabel,
                    this.begin.getNode(),
                    toLabel,
                    this.end.getNode(),
                ]);
            }
            Dom.hide(this.excludeLabel);
        } else {
            Dom.addContent(this.node, [this.begin.getNode(), " to ", this.end.getNode()]);
        }
        if (params.class) {
            Dom.addClass(this.node, params.class);
        }
        this.end.linkErrorMessage(this.begin.getForm());
        this.begin.placeErrorMessage(params.errorLocation || this.end.getForm().getNode(), "after");
    }
    private onKeyDown(evt: KeyboardEvent) {
        if (evt.keyCode === dojo_keys.ESCAPE) {
            this.blur();
        }
    }
    protected valueChange(
        beginChanged: boolean,
        value: V,
        submitMethod?: (begin: V, end: V) => void,
    ): void {
        this.rcm.valueChange(beginChanged, value, submitMethod);
        this.onChange();
    }
    reset() {
        this.begin.setValue("", true);
        this.end.setValue("", true);
        this.begin.resetMax();
        this.end.resetMin();
    }
    setRange(begin: V, end: V) {
        if (begin) {
            this.begin.setValue(begin, true);
        }
        if (end) {
            this.end.setValue(end, true);
        }
    }
    setValue(val: UI.Range<V>) {
        this.setRange(val.begin, val.end);
    }
    toggleExcluded(excluded: boolean): void {
        Dom.show(this.excludeLabel, excluded);
    }
    getBegin() {
        return this.begin.getValue();
    }
    getEnd() {
        return this.end.getValue();
    }
    override focus() {
        this.begin.focus();
    }
    override blur() {
        super.blur();
        this.begin.blur();
        this.end.blur();
    }
    getValue() {
        return RangeWidget.createRange(this.getBegin() || null, this.getEnd() || null);
    }
    setWidth(width: string) {
        this.begin.setWidth(width);
        this.end.setWidth(width);
    }
    static createRange<T>(begin: T, end: T, obj?: any): UI.Range<T> {
        const ret: UI.Range<T> = obj || {};
        if (begin !== null) {
            ret.begin = begin;
        }
        if (end !== null) {
            ret.end = end;
        }
        return ret;
    }
}

module RangeWidget {
    export interface Params<P extends ConstrainedBox.Params> {
        beginPlaceholder?: string;
        endPlaceholder?: string;
        /**
         * Params for the appropriate type of ConstrainedBox, provided to the begin and end boxes.
         * Some options defined here will override these defaults.
         */
        defaultBoxParams?: P;
        boxStyle?: Dom.StyleProps;
        width?: string;
        // Determines whether both "From" and "To" labels are used. False by default.
        addLabels?: boolean;
        // If the "From" and "To" labels are present, determines what units to append to them
        unitType?: string;
        // If addLabels is true, labelsClass is a CSS class applied to the labels.
        labelsClass?: string;
        class?: string | string[];
        // If true, create seperate divs for the begin and end elements and their respective labels
        // Used for cases when the design requires the labels to be placed in a different location.
        seperateBeginAndEndContainers?: boolean;
        // The node after which to place error messages for the range widget--by default, this is
        // the end container.
        errorLocation?: HTMLElement;
        // If true, don't focus the end element after the begin element submits.
        doNotFocusEndAfterBeginSubmit?: boolean;
        timezone?: TimezoneN;
    }
}

export = RangeWidget;
